class CreateKeeperCSVContentUseCase {

    execute(phases) {
        const csvHeader = `Liga;Anwurfzeit;FMP-ID;Begegnung;Zeitnehmer;Sekretär;Spieltechnik;Zusammenarbeit;Auftreten;Summe;Art;Bewertung durch;Kommentar`
        let csvContent = ``;
        for (const phase of phases) {
            for (const match of phase.matches) {
                csvContent += this.createCSVRow({
                    liga: this.__mapPhaseShortname(match.phaseName.shortName),
                    timestamp: this.__formatDate(match.timestamp),
                    matchId: match.matchId,
                    teams: `${match.homeTeam.name} - ${match.awayTeam.name}`,
                    timekeeper: `${match.timekeeper.name}`,
                    scorekeeper: `${match.scorekeeper.name}`,
                    techniqueRating: this.__replaceDotWithComma(match.ratings.technique.weighted),
                    teamworkRating: this.__replaceDotWithComma(match.ratings.teamwork.weighted),
                    appearanceRating: this.__replaceDotWithComma(match.ratings.appearance.weighted),
                    ratingSum: this.__replaceDotWithComma(match.ratings.sum.weighted),
                    comment: this.__removeBadCSVContent(match.comment.trim()),
                    voterType: match.isVoteByDelegate ? 'Delegierter' : 'Schiedsrichter',
                    voter: match.isVoteByDelegate ? match.delegate.name : `${match.refereeA.name} - ${match.refereeB.name}`,
                });
            }
        }
        return `${csvHeader}\n${csvContent}`
    }

    createCSVRow({
                     liga = "",
                     timestamp = "",
                     matchId = "",
                     teams = "",
                     timekeeper = "",
                     scorekeeper = "",
                     techniqueRating = -1,
                     teamworkRating = -1,
                     appearanceRating = -1,
                     ratingSum = -1,
                     comment = "",
                     voterType = "",
                     voter = ""
                 }) {
        return `${liga};${timestamp};${matchId};${teams};${timekeeper};${scorekeeper};${techniqueRating};${teamworkRating};${appearanceRating};${ratingSum};${voterType};${voter};${comment}\n`
    }

    __formatDate(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        // Conditional formatting for minutes
        const formattedMinutes = minutes === 0 ? `0${minutes}` : minutes;

        return `${day}.${month}.${year} ${hours}:${formattedMinutes}`;
    }

    __removeBadCSVContent(text) {
        return text
            .replace(/[\r\n]+/g, ' ')
            .replace(/\s\s+/g, ' ')
            .replace(/;/g, '')
    }

    __replaceDotWithComma(number) {
        return number.toLocaleString('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})
    }

    __mapPhaseShortname(shortname) {
        if (shortname.startsWith('3LF') ) {
            return '3LF';
        }
        if (shortname.startsWith('3LM')) {
            return '3LM';
        }
        if (shortname.startsWith('JBLHmA')) {
            return 'JBLHmA';
        }
        if (shortname.startsWith('JBLHwA')) {
            return 'JBLHwA';
        }
        if (shortname.startsWith('JBLHmB')) {
            return 'JBLHmB';
        }
        if (shortname.startsWith('JBLHwB')) {
            return 'JBLHwB';
        }
        if (shortname.startsWith('DHBPM')) {
            return 'PokM';
        }
        if (shortname.startsWith('DHBPF')) {
            return 'PokF';
        }
        return shortname;
    }
}

module.exports = new CreateKeeperCSVContentUseCase()
